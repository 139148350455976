import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';
import { colors } from 'styles';
import Logo from 'assets/Logo';

const {
  Section,
  Division,
  Paragraph,
  Span,
  Anchor,
  Image,
  SVG,
  Polygon,
  Heading,
} = styledTags;

const HeroSection = () => {
  return (
    <Section tw="relative overflow-hidden bg-primo-primary">
      <Division tw="max-w-screen-xl mx-auto">
        <Division tw="relative z-10 pb-8 bg-primo-primary sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <SVG
            tw="absolute inset-y-0 right-0 hidden w-48 h-full text-primo-primary transform translate-x-1/2 lg:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <Polygon points="50,0 100,0 50,100 0,100" />
          </SVG>

          <Division tw="relative px-4 pt-8 sm:px-6 lg:px-8">
            <Logo tw="-ml-4 h-24" primaryColor="white" secondaryColor={colors.logo.secondary} />
          </Division>

          <Division tw="max-w-screen-xl px-4 mx-auto mt-10 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <Division tw="sm:text-center lg:text-left">
              <Division tw="font-serif font-bold leading-10 tracking-wider text-5xl sm:leading-none">
                <Heading as="h2" tw="mb-2 text-white">
                  Data-Driven Care For
                </Heading>
                <Heading as="h2" tw="text-indigo-500">
                  Post-Acute Care Facilities
                </Heading>
              </Division>
              <Paragraph tw="mt-3 text-base tracking-wide text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Technology-driven and patient-centric <Span tw="text-white font-medium italic">remote patient monitoring</Span>, <Span tw="text-white font-medium italic">cognitive assessments</Span> and <Span tw="text-white font-medium italic">care planning</Span> to help deliver personalized and timely care to your patients.&nbsp;
                <Span tw="text-white font-bold italic">Reduce RTAs</Span> with <Span tw="text-white font-bold italic">no additional costs or staff workload</Span>.
              </Paragraph>
              <Division tw="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <Division tw="rounded-md shadow">
                  <Anchor
                    href="mailto:jackielyn@primo.md"
                    target="_blank"
                    tw="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline md:py-4 md:text-lg md:px-10"
                  >
                    Get Started
                  </Anchor>
                </Division>
              </Division>
            </Division>
          </Division>
        </Division>
      </Division>
      <Division tw="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Image
          tw="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://plus.unsplash.com/premium_photo-1661423762612-e8fae810cb4b?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
        />
      </Division>
    </Section>
  );
};

export default HeroSection;
