import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const {
  Section,
  Division,
  Heading,
  Paragraph,
  Image
} = styledTags;

const ClientsSection = () => {
  return (
    <Section tw='bg-white'>
      <Division tw='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <Division tw='lg:grid lg:grid-cols-1 lg:gap-8 lg:items-center'>
          <Division tw="text-center">
          <Paragraph tw="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            Our Partners
          </Paragraph>
          <Heading
            as="h3"
            tw="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
              Trusted by facilities small and large
          </Heading>
          <Paragraph tw='mt-3 text-lg text-gray-500'>
            We partner with post-acute facilities of all sizes to help deliver remote care to eligible patient populations. We not only deliver better patient outcomes but also help decrease lost revenues.
          </Paragraph>
        </Division>
        </Division>
        <Division tw='mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-0 lg:grid-cols-5'>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/victorianpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/cypressridgecc.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/vasonahc.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/mountainviewhc.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/capitalpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/bridgewoodpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/cedarwoodpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/vineyardpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-16' src='/static/marinpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/edenhc.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/morganhillhc.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/sfpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/sanbrunopa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-32' src='/static/centralgardenspa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/cityviewpa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/canyonspringspa.png'/>
          </Division>
          <Division tw='col-span-1 flex justify-center items-center py-8 px-8'>
            <Image tw='max-h-20' src='/static/santacruzpa.png'/>
          </Division>
        </Division>
      </Division>    
    </Section>
  )
}

export default ClientsSection;
