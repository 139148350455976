import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path } = styledTags;

const Icon = ({ primaryColor, ...otherProps }) => {

  return (
    <SVG xmlns='http://www.w3.org/2000/SVG' viewBox='0 0 128 128' fill={primaryColor} {...otherProps} >
      <Group>
        <Path d="M64.4,68.4c18.8,0,34.2-15.3,34.2-34.2C98.6,15.3,83.3,0,64.4,0S30.3,15.3,30.3,34.2C30.3,53,45.6,68.4,64.4,68.4z
          M64.4,3.8c16.8,0,30.4,13.6,30.4,30.4S81.2,64.6,64.4,64.6S34,51,34,34.2C34,17.4,47.7,3.8,64.4,3.8z"/>
        <Path d="M64.4,58.9c13.6,0,24.7-11.1,24.7-24.7S78.1,9.5,64.4,9.5c-6.2,0-12,2.3-16.6,6.4c-0.8,0.7-0.8,1.9-0.1,2.7
          c0.7,0.8,1.9,0.8,2.7,0.1c3.9-3.5,8.9-5.4,14.1-5.4c11.6,0,21,9.4,21,21s-9.4,21-21,21c-11.6,0-21-9.4-21-21c0-3.5,0.9-6.9,2.5-10
          c0.5-0.9,0.2-2.1-0.8-2.5c-0.9-0.5-2.1-0.2-2.5,0.8c-2,3.6-3,7.7-3,11.8C39.7,47.8,50.8,58.9,64.4,58.9z"/>
        <Path d="M63.7,43c-3-0.2-3.8-2-3.9-2.4c-0.2-0.8-0.9-1.4-1.8-1.4c-1,0-1.9,0.8-1.9,1.9c0,0.2,0,0.3,0.1,0.4l0,0c0,0,0,0,0,0
          c0,0.1,0.1,0.2,0.1,0.3c0.3,1,1.7,3.9,5.8,4.7v2.5c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9v-2.4c1.8-0.3,3-1.1,3.8-1.8
          c1.4-1.3,2.2-3.3,2.2-5.3c0-1.9-0.7-6.4-7.7-7.2c-1.4-0.2-2.3-0.6-2.9-1.3c-0.5-0.6-0.5-1.3-0.5-1.6c0-0.3,0.1-3.2,3.2-3.2
          c2.1,0,2.9,1.3,3,1.6c0.3,0.7,1,1.2,1.7,1.2c1,0,1.9-0.8,1.9-1.9c0-0.3-0.1-0.6-0.2-0.9l0,0c-0.5-1.2-2-3-4.6-3.6v-2.5
          c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9v2.5C58.4,23.6,57,27,57,29.4c0,2.7,1.8,6.1,6.8,6.6c4.3,0.5,4.3,2.7,4.3,3.4
          c0,0.8-0.3,1.9-1.1,2.6C66.3,42.8,65.2,43.1,63.7,43L63.7,43z"/>
        <Path d="M32.6,87c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6l-12.8,13.6c-0.5,0.5-0.7,1.3-0.4,2c0.3,0.7,1,1.1,1.7,1.1h3.7v6.8
          c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9v-8.7c0-1-0.8-1.9-1.9-1.9h-1.2l8.4-8.9l8.4,8.9h-1.2c-1,0-1.9,0.8-1.9,1.9v22.4H25.9v-6.2
          c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v8.1c0,1,0.8,1.9,1.9,1.9h14.4c1,0,1.9-0.8,1.9-1.9v-22.4H44c0.7,0,1.4-0.4,1.7-1.1
          c0.3-0.7,0.2-1.5-0.4-2L32.6,87z"/>
        <Path d="M65.8,74.1c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6L50.3,87.6c-0.5,0.5-0.7,1.3-0.4,2c0.3,0.7,1,1.1,1.7,1.1h3.7v35.3
          c0,1,0.8,1.9,1.9,1.9h14.4c1,0,1.9-0.8,1.9-1.9V90.8h3.7c0.7,0,1.4-0.4,1.7-1.1c0.3-0.7,0.2-1.5-0.4-2L65.8,74.1z M71.6,87
          c-1,0-1.9,0.8-1.9,1.9v35.3H59.1V88.9c0-1-0.8-1.9-1.9-1.9H56l8.4-8.9l8.4,8.9L71.6,87L71.6,87z"/>
        <Path d="M99.1,62.1c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6L83.6,75.7c-0.5,0.5-0.7,1.3-0.4,2c0.3,0.7,1,1.1,1.7,1.1h3.7
          v11.5c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9V77c0-1-0.8-1.9-1.9-1.9h-1.2l8.4-8.9l8.4,8.9h-1.2c-1,0-1.9,0.8-1.9,1.9v47.3H92.4V97.8
          c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v28.3c0,1,0.8,1.9,1.9,1.9h14.4c1,0,1.9-0.8,1.9-1.9V78.8h3.7c0.7,0,1.4-0.4,1.7-1.1
          c0.3-0.7,0.2-1.5-0.4-2L99.1,62.1z"/>
        <Path d="M23.2,34.8c0-1-0.8-1.9-1.9-1.9h-11c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h11C22.4,36.7,23.2,35.9,23.2,34.8z"/>
        <Path d="M23.8,49.6l-3.9,1.5c-1,0.4-1.4,1.5-1.1,2.4c0.3,0.7,1,1.2,1.7,1.2c0.2,0,0.5,0,0.7-0.1l3.9-1.5c1-0.4,1.4-1.5,1.1-2.4
          C25.8,49.7,24.7,49.2,23.8,49.6L23.8,49.6z"/>
        <Path d="M19.8,18.6l3.9,1.5c0.2,0.1,0.5,0.1,0.7,0.1c0.8,0,1.5-0.5,1.7-1.2c0.4-1-0.1-2.1-1.1-2.4l-3.9-1.5c-1-0.4-2.1,0.1-2.4,1.1
          C18.4,17.1,18.9,18.2,19.8,18.6z"/>
        <Path d="M117.7,33h-11c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h11c1,0,1.9-0.8,1.9-1.9C119.6,33.8,118.7,33,117.7,33z"/>
        <Path d="M102.9,53.1l3.9,1.5c0.2,0.1,0.5,0.1,0.7,0.1c0.8,0,1.5-0.5,1.7-1.2c0.4-1-0.1-2.1-1.1-2.4l-3.9-1.5
          c-1-0.4-2.1,0.1-2.4,1.1C101.4,51.6,101.9,52.7,102.9,53.1L102.9,53.1z"/>
        <Path d="M103.6,20.2c0.2,0,0.5,0,0.7-0.1l3.9-1.5c1-0.4,1.4-1.5,1.1-2.4c-0.4-1-1.5-1.4-2.4-1.1l-3.9,1.5c-1,0.4-1.4,1.5-1.1,2.4
          C102.1,19.8,102.8,20.2,103.6,20.2L103.6,20.2z"/>
	    </Group>
    </SVG>
  )
}

export default Icon;