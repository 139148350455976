import React from "react";
// eslint-disable-next-line
import tw from "twin.macro";

import styledTags from "components/styledtags";

const {
  Section,
  Division,
  Paragraph,
  Heading,
  UnorderedList,
  ListItem,
  Image
} = styledTags;

const PitchSection = () => {
  return (
    <Section tw="py-12 bg-blue-200">
      <Division tw="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <Division tw="text-center">
          <Paragraph tw="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            What We Do
          </Paragraph>
          <Heading
            as="h3"
            tw="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
            Personalized Care, Driven By Technology and Data
          </Heading>
        </Division>
        <Division tw="mt-10">
          <UnorderedList tw="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <ListItem tw="mt-10 md:mt-0">
              <Division tw="flex flex-col">
                <Division tw="flex justify-center">
                    <Division tw="flex justify-center items-center bg-indigo-400 h-64 w-64 rounded-full">
                      <Image src="/static/device.png" tw="h-40" />
                    </Division>
                </Division>
                <Division tw="mt-4 ml-4">
                  <Paragraph tw="mt-2 text-lg text-gray-600">
                    Our unique digital health platform uses real-time data from smart vital signs monitors, patient acuity analysis
                    and artificial intelligence to predict and suggest timely interventions to prevent adverse patient outcomes.
                  </Paragraph>
                </Division>
              </Division>
            </ListItem>
            <ListItem>
              <Division tw="flex flex-col">
                <Division tw="flex justify-center">
                    <Division tw="flex justify-center items-center bg-orange-200 h-64 w-64 rounded-full">
                      <Image src="/static/care.png" tw="h-48" />
                    </Division>
                </Division>
                <Division tw="mt-4 ml-4">
                  <Paragraph tw="mt-2 text-lg text-gray-600">
                    Our dedicated and specialized team provides comprehensive remote monitoring and care planning for your patients, helping you keep them 
                    on track to meet their health goals and reduce episodic crises.
                  </Paragraph>
                </Division>
              </Division>
            </ListItem>
          </UnorderedList>
        </Division>
      </Division>
    </Section>
  );
};

export default PitchSection;