import React from "react";
// eslint-disable-next-line
import tw from "twin.macro";

import styledTags from "components/styledtags";
import { 
  HeroSection, 
  PitchSection,
  BenefitsSection, 
  StatsSection,
  ClientsSection,
  FooterSection 
} from "components/landing";

const {
  Article,
} = styledTags;


const Home = () => {
  return (
    <Article>
      <HeroSection />
      <PitchSection />
      <BenefitsSection />
      <StatsSection />
      <ClientsSection />
      <FooterSection />
    </Article>
  );
};

export default Home;
