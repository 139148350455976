import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path } = styledTags;

const Icon = ({ primaryColor, ...otherProps }) => {

  return (
    <SVG xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128' fill={primaryColor} {...otherProps} >
      <Group>
        <Path d="M101.6,95c-1,0-1.9,0.8-1.9,1.9v17c0,5-4,9-9,9H12.8c-5,0-9-4-9-9v-87c0-5,4-9,9-9h10.9c1,0,1.9-0.8,1.9-1.9
          c0-1-0.8-1.9-1.9-1.9H12.8C5.7,14.1,0,19.8,0,26.8v87c0,7,5.7,12.8,12.8,12.8h77.9c7,0,12.8-5.7,12.8-12.8v-17
          C103.4,95.9,102.6,95,101.6,95L101.6,95z"/>
        <Path d="M79.8,17.8h10.9c5,0,9,4,9,9v11.7c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9V26.8c0-7-5.7-12.8-12.8-12.8H79.8
          c-1,0-1.9,0.8-1.9,1.9S78.8,17.8,79.8,17.8z"/>
        <Path d="M9.4,44.5c-1,0-1.9,0.8-1.9,1.9v67.5c0,2.9,2.4,5.2,5.2,5.2h77.9c2.9,0,5.2-2.4,5.2-5.2V97c0-1-0.8-1.9-1.9-1.9
          s-1.9,0.8-1.9,1.9v16.9c0,0.8-0.7,1.5-1.5,1.5H12.8c-0.8,0-1.5-0.7-1.5-1.5V46.4C11.3,45.3,10.4,44.5,9.4,44.5L9.4,44.5z"/>
        <Path d="M24.1,21.6H12.8c-2.9,0-5.2,2.4-5.2,5.2v11.5c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9V26.8c0-0.8,0.7-1.5,1.5-1.5h11.3
          c1,0,1.9-0.8,1.9-1.9S25.1,21.6,24.1,21.6z"/>
        <Path d="M79.3,25.4h11.3c0.8,0,1.5,0.7,1.5,1.5v11.8c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9V26.8c0-2.9-2.4-5.2-5.2-5.2H79.3
          c-1,0-1.9,0.8-1.9,1.9S78.3,25.4,79.3,25.4z"/>
        <Path d="M36.4,27.2h8.7c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9h-8.7c-1.9,0-3.4-1.5-3.4-3.4v-5.9c0-1.9,1.5-3.4,3.4-3.4h3
          c1.4,0,2.5-1.1,2.5-2.5V6.4c0-0.7,0.5-1.2,1.2-1.2h17.1c0.7,0,1.2,0.5,1.2,1.2v1.9c0,1.4,1.1,2.5,2.5,2.5h3c1.9,0,3.4,1.5,3.4,3.4
          V20c0,1.9-1.5,3.4-3.4,3.4H53.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9H67c3.9,0,7.1-3.2,7.1-7.1v-5.9C74.2,10.2,71,7,67,7h-1.8
          V6.4c0-2.7-2.2-5-5-5H43.2c-2.7,0-5,2.2-5,5V7h-1.8c-3.9,0-7.1,3.2-7.1,7.1V20C29.3,24,32.5,27.2,36.4,27.2L36.4,27.2z"/>
        <Path d="M59.6,15.3c0-1-0.8-1.9-1.9-1.9H45.8c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9h11.9C58.7,17.2,59.6,16.3,59.6,15.3z"/>
        <Path d="M125.1,51.1c-4.9-7.6-14.7-10.5-22.9-6.6l-4.3,2c-0.1,0.1-0.3,0.1-0.4,0l-4.3-2c-8.2-3.8-18.1-1-22.9,6.6
          c-2.5,3.8-3.4,8.4-2.6,12.9c0.8,4.5,3.2,8.5,6.8,11.3l20.6,15.9c0.8,0.6,1.7,0.9,2.6,0.9c0.9,0,1.9-0.3,2.6-0.9l7.9-6.1
          c0.8-0.6,1-1.8,0.3-2.6c-0.6-0.8-1.8-1-2.6-0.3l-7.9,6.1c-0.2,0.2-0.5,0.2-0.7,0L76.8,72.3c-0.8-0.6-1.5-1.3-2.2-2.1h7.9
          c0.8,0,1.6-0.6,1.8-1.4l2.1-7.7l5.9,16.3c0.3,0.7,1,1.2,1.8,1.2h0c0.8,0,1.5-0.5,1.8-1.2l5.6-15l1.7,5.5c0.2,0.8,1,1.3,1.8,1.3
          h16.7c-0.8,1.1-1.8,2.1-2.9,3l-6.3,4.9c-0.8,0.6-1,1.8-0.3,2.6c0.6,0.8,1.8,1,2.6,0.3l6.3-4.9c3.6-2.8,6-6.8,6.8-11.3
          C128.5,59.5,127.6,54.9,125.1,51.1L125.1,51.1z M106.3,65.5l-2.9-9.5c-0.2-0.8-0.9-1.3-1.7-1.3c-0.8,0-1.5,0.5-1.8,1.2l-5.7,15.4
          l-6.1-17c-0.3-0.8-1-1.3-1.8-1.2c-0.8,0-1.5,0.6-1.7,1.4l-3.3,12h-8.8c-0.4-1-0.7-2-0.9-3.1c-0.6-3.6,0.1-7.2,2.1-10.2
          c3.9-6,11.7-8.3,18.2-5.3l4.3,2c1.1,0.5,2.4,0.5,3.6,0l4.3-2c6.5-3,14.3-0.8,18.2,5.3c1.9,3,2.7,6.7,2.1,10.2
          c-0.1,0.7-0.3,1.5-0.6,2.2L106.3,65.5L106.3,65.5z"/>
        <Path d="M22.7,53.4h8.8c2.1,0,3.9-1.7,3.9-3.9v-9.7c0-2.1-1.7-3.9-3.9-3.9h-8.8c-2.1,0-3.9,1.7-3.9,3.9v9.7
          C18.8,51.6,20.6,53.4,22.7,53.4z M22.6,39.7c0-0.1,0.1-0.1,0.1-0.1h8.8c0.1,0,0.1,0.1,0.1,0.1v9.7c0,0.1-0.1,0.1-0.1,0.1h-8.8
          c-0.1,0-0.1-0.1-0.1-0.1L22.6,39.7z"/>
        <Path d="M18.8,74.2c0,2.1,1.7,3.9,3.9,3.9h8.8c2.1,0,3.9-1.7,3.9-3.9v-9.7c0-2.1-1.7-3.9-3.9-3.9h-8.8c-2.1,0-3.9,1.7-3.9,3.9
          L18.8,74.2z M22.6,64.5c0-0.1,0.1-0.1,0.1-0.1h8.8c0.1,0,0.1,0.1,0.1,0.1v9.7c0,0.1-0.1,0.1-0.1,0.1h-8.8c-0.1,0-0.1-0.1-0.1-0.1
          L22.6,64.5z"/>
        <Path d="M18.8,98.9c0,2.1,1.7,3.9,3.9,3.9h8.8c2.1,0,3.9-1.7,3.9-3.9v-9.7c0-2.1-1.7-3.9-3.9-3.9h-8.8c-2.1,0-3.9,1.7-3.9,3.9
          L18.8,98.9z M22.6,89.2c0-0.1,0.1-0.1,0.1-0.1h8.8c0.1,0,0.1,0.1,0.1,0.1v9.7c0,0.1-0.1,0.1-0.1,0.1h-8.8c-0.1,0-0.1-0.1-0.1-0.1
          L22.6,89.2z"/>
        <Path d="M41,40.5h21.9c1,0,1.9-0.8,1.9-1.9s-0.8-1.9-1.9-1.9H41c-1,0-1.9,0.8-1.9,1.9S40,40.5,41,40.5z"/>
        <Path d="M41,52.6h21.9c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9H41c-1,0-1.9,0.8-1.9,1.9C39.1,51.7,40,52.6,41,52.6z"/>
        <Path d="M41,65.2h21.9c1,0,1.9-0.8,1.9-1.9s-0.8-1.9-1.9-1.9H41c-1,0-1.9,0.8-1.9,1.9C39.1,64.4,40,65.2,41,65.2z"/>
        <Path d="M65.9,73.5H41c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9h24.9c1,0,1.9-0.8,1.9-1.9S66.9,73.5,65.9,73.5z"/>
        <Path d="M75.6,86.1H41c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h34.6c1,0,1.9-0.8,1.9-1.9C77.5,87,76.7,86.1,75.6,86.1z"/>
        <Path d="M83.5,98.1H41c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h42.5c1,0,1.9-0.8,1.9-1.9C85.4,99,84.6,98.1,83.5,98.1z"/>
      </Group>
    </SVG>
  )
}

export default Icon;