import React from 'react';

import { Provider, Client, dedupExchange, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';

const client = new Client({
  url: 'http://localhost:8000/graphpi/',
  exchanges: [
    dedupExchange, 
    cacheExchange({

    }), 
    fetchExchange  
  ]
});

const DataProvider = (props) => {
  return (
    <Provider value={client}>
      {props.children}
    </Provider>
  )
}

export default DataProvider;
