import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path } = styledTags;

const Icon = ({ primaryColor, ...otherProps }) => {

  return (
    <SVG xmlns='http://www.w3.org/2000/SVG' viewBox='0 0 128 128' fill={primaryColor} {...otherProps} >
      <Group>
        <Path d="M102.7,88.8h-4v-4c0-2.2-1.8-4-4-4h-4c-2.2,0-4,1.8-4,4v4h-4c-2.2,0-4,1.8-4,4v4c0,2.2,1.8,4,4,4h4v4c0,2.2,1.8,4,4,4h4
          c2.2,0,4-1.8,4-4v-4h4c2.2,0,4-1.8,4-4v-4C106.7,90.5,104.9,88.8,102.7,88.8z M102.7,96.8h-8v8h-4v-8h-8v-4h8v-8h4v8l8,0V96.8z"/>
        <Path d="M106.2,66.8c-4.2,0-8.2,1.3-11.5,3.6V57.1c1.3-3.7,4.4-6.6,6.1-7.9L102,50c2.7,1.8,5.8,2.7,9,2.7c6.7,0,12.6-4,15.1-10.2
          l1-2.5l-3.9-2.6c-2.6-1.7-5.6-2.7-8.8-2.7c-5.5,0-10.7,2.9-13.6,7.7l-1.8,2.9c-0.8,0.6-2.7,2-4.5,4.2V29.1c1.4-3.9,4.8-6.9,6.4-8.1
          l3,1c1.5,0.5,3.1,0.8,4.7,0.8c5.6,0,10.7-3.1,13.3-8.2l1.2-2.5l-4.9-3.3c-2-1.3-4.4-2.1-6.8-2.1c-5,0-9.5,3-11.3,7.7l-1.2,3
          c-0.9,0.7-2.8,2.2-4.7,4.4c-0.6-3-1.9-5.8-3.9-8.1c-0.1-0.1-0.1-0.1-0.1-0.1l-1.3-3.2c-1.9-4.7-6.3-7.7-11.3-7.7
          c-2.4,0-4.8,0.7-6.8,2.1l-4.9,3.3l1.2,2.5c2.5,5.1,7.6,8.2,13.3,8.2c1.6,0,3.2-0.3,4.7-0.8l2.7-0.9c1.7,2.2,2.7,5,2.7,8V38
          c-0.2-0.2-0.3-0.3-0.4-0.5l-1.9-3.1c-2.8-4.7-8-7.7-13.6-7.7c-3.1,0-6.2,0.9-8.8,2.7L62.2,32l1,2.5c2.5,6.2,8.4,10.2,15.1,10.2
          c3.2,0,6.3-0.9,9-2.7l0.9-0.6c0.6,0.8,1.5,2.1,2.4,3.8v25.1c-3.4-2.4-7.3-3.6-11.5-3.6c-4.6,0-9,1.6-12.5,4.4v-0.4
          c0-5.5-3.2-10.3-8-12.5V26.7c0-13.2-10.8-24-24-24s-24,10.8-24,24v31.5c-4.8,2.2-8,7-8,12.5v6h4v-6c0-4.8,3.4-8.9,8.2-9.7l10.8-1.8
          c2.9-0.5,5-3,5-5.9v-1.1c1.3,0.3,2.6,0.5,4,0.5s2.7-0.2,4-0.5v1.1c0,2.9,2.1,5.4,5,5.9l10.8,1.8c4.7,0.8,8.2,4.9,8.2,9.7v4.6
          c-2.6,3.7-4,8-4,12.5v1.3c0,1.2,0.1,2.4,0.3,3.6h-5.1L48.6,82L43,95l-8.5-27.5l-10,38l-6.4-20.7H2.7v4h12.5l9.6,31.3l10-38
          l7.5,24.5l6.4-15l2.6,5.2h8.5c1.6,4.9,4.6,9.4,8.9,12.6l23.9,17.9l23.9-17.9c6.3-4.7,10.1-12.3,10.1-20.2v-1.3c0-5.1-1.8-10-5-13.9
          C117.8,69.4,112.2,66.8,106.2,66.8L106.2,66.8z M104.4,44.5c2.1-3.5,6-5.7,10.1-5.7c2.3,0,4.6,0.7,6.6,2l1.2,0.8
          c-2,4.4-6.3,7.2-11.2,7.2c-2.4,0-4.8-0.7-6.8-2.1l-0.8-0.5L104.4,44.5z M104,15.9c1.3-3.1,4.2-5.2,7.6-5.2c1.6,0,3.2,0.5,4.6,1.4
          l1.9,1.3c-1.9,3.3-5.4,5.3-9.3,5.3c-1.2,0-2.3-0.2-3.4-0.6l-2-0.7L104,15.9z M22.7,36.8v-4.2c0-3.1,1.4-5.9,3.8-7.8l2.8-2.2
          c2.1,2.9,5.4,4.9,9.2,5.2l6.4,0.6c1,0.1,1.8,1,1.8,2v6.4c0,6.6-5.4,12-12,12S22.7,43.4,22.7,36.8L22.7,36.8z M44.4,55.3
          c-1-0.2-1.7-1-1.7-2v-2.8c4.8-2.8,8-7.9,8-13.8v-6.4c0-3.1-2.3-5.7-5.4-6l-6.4-0.6c-4.6-0.5-8.1-4.3-8.1-9h-4
          c0,1.4,0.2,2.8,0.7,4.1l-3.4,2.7c-3.3,2.7-5.3,6.7-5.3,10.9v4.2c0,5.9,3.2,11.1,8,13.8v2.8c0,1-0.7,1.8-1.7,2l-10.3,1.7V26.8
          c0-11,9-20,20-20s20,9,20,20v30.3L44.4,55.3z M84,14.2c-1.1,0.4-2.3,0.6-3.4,0.6c-3.9,0-7.4-2-9.3-5.3l1.9-1.3
          c1.3-0.9,2.9-1.4,4.6-1.4c3.4,0,6.4,2,7.6,5.2l0.6,1.6L84,14.2z M85.2,38.7c-2,1.3-4.4,2.1-6.8,2.1c-4.9,0-9.2-2.8-11.2-7.2
          l1.2-0.8c1.9-1.3,4.2-2,6.6-2c4.1,0,8,2.2,10.1,5.7l1,1.7L85.2,38.7z M122.7,89.1c0,6.6-3.2,13-8.5,17l-21.5,16.1l-21.5-16.1
          c-5.3-4-8.5-10.3-8.5-17v-1.3c0-4.1,1.5-8.2,4.1-11.3c3.1-3.7,7.6-5.8,12.4-5.8c4.3,0,8.4,1.7,11.4,4.7l2.1,2.1l2.1-2.1
          c3-3,7.1-4.7,11.4-4.7c4.8,0,9.3,2.1,12.4,5.8c2.6,3.2,4.1,7.2,4.1,11.3V89.1z"/>
      </Group>
    </SVG>
  )
}

export default Icon;