import React from "react";
// eslint-disable-next-line
import tw from "twin.macro";

import styledTags from "components/styledtags";
import { colors } from "styles";
import Logo from "assets/Logo";

const {
  Division,
  Footer, 
  Paragraph,
} = styledTags;

const FooterSection = () => {
  return (
    <Footer tw="bg-indigo-700">
      <Division tw="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <Division tw="xl:grid xl:grid-cols-1 xl:gap-8">
          <Division tw="xl:col-span-1 text-gray-200 text-base leading-6">
            <Logo tw="-ml-2 h-10" primaryColor="white" secondaryColor={colors.secondaryColor} />
            <Paragraph tw="mt-4">
              &copy; 2024 Soar Health Ventures, Inc. All rights reserved.
            </Paragraph>
          </Division>
        </Division>
      </Division>
    </Footer>
  );
};

export default FooterSection;