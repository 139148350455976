import React from "react";
// eslint-disable-next-line
import tw from "twin.macro";

import styledTags from "components/styledtags";

const {
  Section,
  Division,
  Paragraph,
  Heading,
} = styledTags;

const ComparisonSection = () => {
  return (
    <Section tw="bg-white">
      <Division tw="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
        <Division tw="max-w-4xl mx-auto">
          <Division tw="text-center">
            <Paragraph tw="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Benefits of Outsourcing CCM
            </Paragraph>
            <Heading as="h2" tw="mt-2 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Why care providers choose us to handle their CCM
            </Heading>
          </Division>
          <Paragraph tw="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            There are several benefits to outsourcing CCM to a specialized CCM provider 
            like PrimoCare, with none of the overheads and hassles of managing an effective
            in-house CCM program.
          </Paragraph>
          <Division tw="mt-6 text-center mx-auto">
            <Division tw="flex text-gray-900 font-bold uppercase">
              <Division tw="w-1/3 py-2"></Division>
              <Division tw="w-1/3 py-2">In-House</Division>
              <Division tw="w-1/3 py-2">Primocare</Division>
            </Division>
            <Division tw="border border-blue-700">
              <Division tw="flex bg-purple-100 text-gray-900">
                <Division tw="w-1/3 pl-8 py-6 text-left font-bold">Patient Enrollment</Division>
                <Division tw="w-1/3 py-6 text-red-600 uppercase font-bold">Slow</Division>
                <Division tw="w-1/3 py-6 text-green-600 uppercase font-bold">Fast</Division>
              </Division>          
              <Division tw="flex text-gray-900">
                <Division tw="w-1/3 pl-8 py-6 text-left font-bold">Enrollment Rate</Division>
                <Division tw="w-1/3 py-6 text-red-600 uppercase font-bold">Low (15-20%)</Division>
                <Division tw="w-1/3 py-6 text-green-600 uppercase font-bold">High (80%)</Division>
              </Division>
              <Division tw="flex bg-purple-100 text-gray-900">
                <Division tw="w-1/3 pl-8 py-6 text-left font-bold">Care Management</Division>
                <Division tw="w-1/3 py-6 text-red-600 uppercase font-bold">Business Hours</Division>
                <Division tw="w-1/3 py-6 text-green-600 font-bold">24x7</Division>
              </Division>
              <Division tw="flex text-gray-900">
                <Division tw="w-1/3 pl-8 py-6 text-left font-bold">Upfront Costs</Division>
                <Division tw="w-1/3 py-6 text-red-600 uppercase font-bold">High</Division>
                <Division tw="w-1/3 py-6 text-green-600 uppercase font-bold">$0</Division>
              </Division>
              <Division tw="flex bg-purple-100 text-gray-900">
                <Division tw="w-1/3 pl-8 py-6 text-left font-bold">Ongoing Costs</Division>
                <Division tw="w-1/3 py-6 text-red-600 uppercase font-bold">High</Division>
                <Division tw="w-1/3 py-6 text-green-600 uppercase font-bold">Low</Division>
              </Division>
              <Division tw="flex text-gray-900">
                <Division tw="w-1/3 pl-8 py-6 text-left font-bold">CCM Caseload</Division>
                <Division tw="w-1/3 py-6 text-red-600 uppercase font-bold">Low</Division>
                <Division tw="w-1/3 py-6 text-green-600 uppercase font-bold">High</Division>
              </Division>
              <Division tw="flex bg-purple-400 text-gray-900 border-t border-dotted border-purple-700">
                <Division tw="w-1/3 pl-8 py-6 text-left uppercase font-bold">Overall Profits</Division>
                <Division tw="w-1/3 py-6 text-red-700 uppercase font-bold">Low</Division>
                <Division tw="w-1/3 py-6 text-green-700 uppercase font-bold">High</Division>
              </Division>
            </Division>          
          </Division>
        </Division>
      </Division>
    </Section>
  );
};

export default ComparisonSection;
