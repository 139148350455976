import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path } = styledTags;

const Icon = ({ primaryColor, ...otherProps }) => {

  return (
    <SVG xmlns='http://www.w3.org/2000/SVG' viewBox='0 0 128 128' fill={primaryColor} {...otherProps} >
      <Group>
        <Path d="M121.1,17.2l-5.5-4.8l5.5-4.8c0.9-0.8,1.1-1.9,0.7-3c-0.4-1.1-1.4-1.7-2.5-1.7H96.6v-1c0-1-0.8-1.9-1.9-1.9
          s-1.9,0.8-1.9,1.9v25.3l-15.4,8.4c-0.1-0.7-0.3-1.3-0.6-2l-2.2-4.2c-1.5-2.9-5.1-4.1-8-2.6L65,27.6c-1.4,0.7-2.5,1.9-2.9,3.5
          c-0.5,1.5-0.4,3.1,0.4,4.5l2.2,4.2c0.5,1,1.3,1.8,2.1,2.3c-1.2,1.6-1.7,3.5-2.3,5.4L64.1,49c-0.3,1,0.3,2,1.3,2.3
          c0.2,0,0.3,0.1,0.5,0.1c0.8,0,1.6-0.5,1.8-1.4l0.4-1.4c0.8-2.9,1.3-4.5,2.9-5.3l2.9-1.6l0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2l20.5-11.2
          c-0.1,2.6-1.5,5-3.8,6.4L78,44.7c0,0,0,0,0,0l-4.2,2.5c-0.9,0.5-1.2,1.7-0.6,2.6c0.5,0.8,1.6,1.1,2.4,0.7l-3.2,9.5L70.2,61l-6.3,2.6
          l1.8-6.4c0.3-1-0.3-2-1.3-2.3c-1-0.3-2,0.3-2.3,1.3l-2.9,10.1c0,0.1-0.1,0.3-0.1,0.4l-0.7,13.7c0,0.5,0.1,1.5-0.2,2L50,99.9h-6
          c-3.5,0-6.4,2.9-6.4,6.4v5.4c-0.8-0.4-1.7-0.6-2.7-0.6H19.6c-3.5,0-6.4,2.9-6.4,6.4v6.7H8.7c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9
          h110.6c1,0,1.9-0.8,1.9-1.9s-0.8-1.9-1.9-1.9h-4.9V75.9c0-3.5-2.9-6.4-6.4-6.4H96.6V36.8c2.1-2.8,2.9-6.5,1.9-10
          c-0.2-0.6-0.5-1-1.1-1.2c-0.3-0.1-0.5-0.2-0.8-0.1v-3.4h22.6c1.1,0,2.1-0.7,2.5-1.7S121.9,18,121.1,17.2z M65.7,32.2
          c0.2-0.6,0.6-1,1.1-1.3l1.7-0.9c0.3-0.2,0.7-0.2,1-0.2c0.8,0,1.6,0.4,1.9,1.2l2.2,4.2c0.5,1,0.2,2.2-0.7,2.8l-0.6,0.3l-1.3,0.7
          c-1.1,0.5-2.4,0.1-2.9-0.9l-2.2-4.2C65.5,33.4,65.5,32.8,65.7,32.2z M61.9,100.5c-0.6-0.3-1.3-0.5-2.1-0.5l2.1-2.7V100.5z
          M70.8,85.3c0.9-1.1,1.2-2.3,1.4-3.4c0.1-0.2,1-5.3,1-5.3s0.9,0.5,1,0.6c0.1,0,0.1,0.1,0.1,0.2l1,10.4H69L70.8,85.3z M81.9,75
          l-1.6,12.8H79L78,77.1c-0.1-1.4-1-2.6-2.2-3.2l-1.9-1.2l1.3-7c0,0,5.3,6.3,5.4,6.5C81.6,73.6,82,74.2,81.9,75z M61.6,84.1
          c0.4-1.1,0.6-2.3,0.5-3.5l0.7-12.5l8.7-3.6l-1.6,8.7c0,0,0,0,0,0.1l-1.3,7.2c-0.2,0.7-0.3,1.9-0.8,2.5L55.2,99.9h-1L61.6,84.1z
          M16.9,117.5c0-1.5,1.2-2.7,2.7-2.7h15.3c1.5,0,2.7,1.2,2.7,2.7v6.7H16.9V117.5z M41.3,117.5v-11.2c0-1.5,1.2-2.7,2.7-2.7h15.3
          c1.5,0,2.7,1.2,2.7,2.7v18H41.3L41.3,117.5L41.3,117.5z M110.6,75.9v48.4H90V113c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9v11.2
          H65.7v-30c0-1.5,1.2-2.7,2.7-2.7h15.3c1.5,0,2.7,1.2,2.7,2.7v9.4c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9V75.9
          c0-1.5,1.2-2.7,2.7-2.7h2.1c0,0,0,0,0,0c0,0,0,0,0,0H108C109.4,73.2,110.6,74.4,110.6,75.9z M92.7,69.5c-3.5,0-6.4,2.9-6.4,6.4v12.5
          c-0.7-0.3-1.4-0.5-2.2-0.6l1.5-12.4c0.3-2.4-0.9-4-2.1-5.6c-0.1-0.2-7.4-8.8-7.4-8.8l4.5-13.5l12.3-7.5v29.4H92.7z M96.6,18.2V6.6
          h19.8l-4.3,3.8c-0.6,0.5-0.9,1.3-0.9,2s0.3,1.5,0.9,2l4.3,3.8L96.6,18.2L96.6,18.2z"/>
	    </Group>
    </SVG>
  )
}

export default Icon;