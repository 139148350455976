import React from "react";
// eslint-disable-next-line
import tw from "twin.macro";

import styledTags from "components/styledtags";
import { colors } from "styles";
import { 
  DeviceIcon,
  CoachIcon,
  ClipboardIcon,
  CareIcon
} from "assets/icons";

const {
  Section,
  Division,
  Paragraph,
  Heading,
  UnorderedList,
  ListItem,
} = styledTags;

const BenefitsSection = () => {
  return (
    <Section tw="py-12 bg-white">
      <Division tw="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <Division tw="text-center">
          <Paragraph tw="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            Patient-Centric Care
          </Paragraph>
          <Heading
            as="h3"
            tw="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
            A better way to manage post-acute patients
          </Heading>
        </Division>
        <Paragraph tw="mt-4 max-w-3xl text-xl leading-7 text-gray-500 lg:mx-auto">
          PrimoMD uses smart technology and proven clinical methods to predict and prevent unwarranted rehospitalizations.  
        </Paragraph>
        <Division tw="mt-10">
          <UnorderedList tw="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <ListItem>
              <Division tw="flex">
                <Division tw="flex-shrink-0">
                    <DeviceIcon
                      tw="h-12 w-12"
                      primaryColor={colors.secondary}
                    />
                </Division>
                <Division tw="ml-4">
                  <Heading
                    as="h5"
                    tw="text-xl leading-6 font-medium text-gray-900"
                  >
                    Remote Patient Monitoring
                  </Heading>
                  <Paragraph tw="mt-2 text-lg leading-6 text-gray-500">
                    We equip facilities with smart vital signs monitors, track patients' vitals in real-time and alert facility staff when interventions may be necessary.
                  </Paragraph>
                </Division>
              </Division>
            </ListItem>
            <ListItem tw="mt-10 md:mt-0">
              <Division tw="flex">
                <Division tw="flex-shrink-0">
                    <CoachIcon
                      tw="h-12 w-12"
                      primaryColor={colors.secondary}
                    />
                </Division>
                <Division tw="ml-4">
                  <Heading
                    as="h5"
                    tw="text-xl leading-6 font-medium text-gray-900"
                  >
                    Patient Acuity Modeling
                  </Heading>
                  <Paragraph tw="mt-2 text-lg leading-6 text-gray-500">
                    We constantly track and analyze patients' clinical histories and cognitive abilities to build an accurate picture of their acuity and risk profiles.
                  </Paragraph>
                </Division>
              </Division>
            </ListItem>
            <ListItem tw="mt-10 md:mt-0">
              <Division tw="flex">
                <Division tw="flex-shrink-0">
                  <ClipboardIcon
                    tw="h-12 w-12"
                    primaryColor={colors.secondary}
                  />
                </Division>
                <Division tw="ml-4">
                  <Heading
                    as="h5"
                    tw="text-xl leading-6 font-medium text-gray-900"
                  >
                    Personalized Care Programs
                  </Heading>
                  <Paragraph tw="mt-2 text-lg leading-6 text-gray-500">
                      We help deliver clinically-proven chronic care programs through personalized care plans that are tailored to each patient's histories and needs.
                  </Paragraph>
                </Division>
              </Division>
            </ListItem>
            <ListItem tw="mt-10 md:mt-0">
              <Division tw="flex">
                <Division tw="flex-shrink-0">
                  <CareIcon
                    tw="h-12 w-12"
                    primaryColor={colors.secondary}
                  />
                </Division>
                <Division tw="ml-4">
                  <Heading
                    as="h5"
                    tw="text-xl leading-6 font-medium text-gray-900"
                  >
                    Around The Clock Care
                  </Heading>
                  <Paragraph tw="mt-2 text-lg leading-6 text-gray-500">
                    Our dedicated care team is available 24 hours a day/7 days a week, over voice or video, to address your patients' care and medical needs.
                  </Paragraph>
                </Division>
              </Division>
            </ListItem>
          </UnorderedList>
        </Division>
      </Division>
    </Section>
  );
};

export default BenefitsSection;
