import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const {
  Section,
  Division,
  Paragraph,
  DescriptionList,
  Term,
  Description
} = styledTags;

const StatsSection = () => {
  return (
    <Section tw='bg-indigo-200'>
      <Division tw='max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20'>
        <Division tw='max-w-4xl mx-auto'>
          <Division tw=' text-center'>
            <Paragraph tw='text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase'>
              PrimoMD In Numbers
            </Paragraph>
          </Division>
        </Division>
        <DescriptionList tw='mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8'>
          <Division tw='flex flex-col'>
            <Term tw='order-2 mt-2 text-base leading-6 uppercase font-medium text-gray-600'>
              Patients Monitored Monthly           
            </Term>
            <Description tw='order-1 text-5xl leading-none font-extrabold text-indigo-800'>
              3500+
            </Description>
          </Division>
          <Division tw='flex flex-col mt-10 sm:mt-0'>
            <Term tw='order-2 mt-2 text-base leading-6 uppercase font-medium text-gray-600'>
              Reduction In RTA
            </Term>
            <Description tw='order-1 text-5xl leading-none font-extrabold text-indigo-800'>
              8%
            </Description>
          </Division>
          <Division tw='flex flex-col mt-10 sm:mt-0'>
            <Term tw='order-2 mt-2 text-base leading-6 uppercase font-medium text-gray-600'>
              Net Promoter Score
            </Term>
            <Description tw='order-1 text-5xl leading-none font-extrabold text-indigo-800'>
              90%
            </Description>
          </Division>
        </DescriptionList>
      </Division>
    </Section>
  )
}

export default StatsSection;
