import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path, Circle } = styledTags;

const Icon = ({ primaryColor, ...otherProps }) => {

  return (
    <SVG xmlns='http://www.w3.org/2000/SVG' viewBox='0 0 128 128' fill={primaryColor} {...otherProps} >
      <Group>
        <Path d="M88,2H40c-5.5,0-10,4.5-10,10v104c0,5.5,4.5,10,10,10h48c5.5,0,10-4.5,10-10V12C98,6.5,93.5,2,88,2z M77.6,6l-1.2,6H51.6
          l-1.2-6H77.6z M94,116c0,3.3-2.7,6-6,6H40c-3.3,0-6-2.7-6-6V12c0-3.3,2.7-6,6-6h6.4l1.7,8.4c0.2,0.9,1,1.6,2,1.6h28
          c1,0,1.8-0.7,2-1.6L81.6,6H88c3.3,0,6,2.7,6,6V116z"/>
        <Path d="M76.2,93.6h-1.1c-0.3,0-0.6,0.1-0.8,0.3L64,104.2L53.7,93.9c-0.2-0.2-0.5-0.3-0.8-0.3l-1.2,0c-13.5,1.4-20.9,7.6-20.9,17.7
          c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1c0-9.9,8-13.7,15.7-15.1l-1.2,2.4c-0.3,0.5-0.1,1.2,0.5,1.5l3.8,1.9v3.7
          c0,0.5,0.3,0.9,0.8,1.1l10.3,3.1v1.4c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1v-1.4l10.3-3.1c0.5-0.1,0.8-0.6,0.8-1.1V102
          l3.8-1.9c0.5-0.3,0.8-0.9,0.5-1.5l-1.2-2.4c7.8,1.4,15.7,5.2,15.7,15.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1
          C97.2,101.2,89.8,95,76.2,93.6z M54,105v-3.6c0-0.4-0.2-0.8-0.6-1L50,98.6l1.4-2.8c0.1,0,0.3,0,0.4-0.1h0.6l10.4,10.4v1.4L54,105z
          M78,98.6l-3.4,1.7c-0.4,0.2-0.6,0.6-0.6,1v3.6l-8.9,2.7v-1.4l10.4-10.4h0.5c0.2,0,0.4,0,0.6,0.1L78,98.6z"/>
        <Path d="M43.4,84.5c4.1,3,8.9,5,14,5.6v3.5c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1v-3.4c1.4,0.7,2.9,1.1,4.4,1.2
          c1.5-0.1,3.1-0.5,4.4-1.2v3.4c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1v-3.5c5.1-0.7,9.9-2.6,14-5.6c2.6-2.1,1.8-9.9-2.6-25.4
          c-0.1-0.4-0.2-0.6-0.2-0.9l2.1-5.2c0.2-0.5,0-1.1-0.5-1.4c-8.3-4.7-13.7-5.7-19.4-5.7s-11.1,1-19.4,5.7c-0.5,0.3-0.7,0.9-0.5,1.4
          l2.1,5.2c-0.1,0.2-0.1,0.5-0.2,0.9C41.5,74.6,40.7,82.4,43.4,84.5z M51.8,77v-5.9c6.1-1,12-3,17.5-5.9c1.3,1.4,4.2,4.5,6.9,5.7V77
          c0,6.5-8.2,12.2-12.2,12.2S51.8,83.5,51.8,77z M47.4,73.7c0-1.2,1-2.2,2.2-2.2v4.4C48.4,75.9,47.4,74.9,47.4,73.7z M78.4,71.4
          c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2V71.4z M64,48.2c5.1,0,10.1,0.9,17.5,4.9l-3.6,9c-4.4-2.3-11.4-2.8-13.9-2.8s-9.4,0.5-13.9,2.8
          l-3.6-9C53.9,49,58.9,48.2,64,48.2z M48.6,64.1c0.1,0.3,0.4,0.6,0.7,0.6s0.7,0,0.9-0.1c3.4-2.3,10.2-3.1,13.8-3.1
          c3.5,0,10.4,0.9,13.8,3.1c0.3,0.2,0.6,0.2,0.9,0.1c0.3-0.1,0.6-0.3,0.7-0.6l1-2.5c4.9,17.8,3.4,20.7,2.8,21.2
          c-3.2,2.3-6.8,3.9-10.6,4.8c2.9-2.4,5.3-5.7,5.7-9.5h0.1c2.4,0,4.4-2,4.4-4.4c0-2.4-2-4.4-4.4-4.4c-2,0-6-3.8-8-6.3
          c-0.4-0.4-1-0.5-1.5-0.2c-0.1,0-6.7,4.3-19.5,6.5c-2.4,0.1-4.4,2.1-4.3,4.5c0.1,2.4,2.1,4.4,4.5,4.3h0.1c0.4,3.8,2.8,7.1,5.7,9.5
          c-3.8-0.8-7.5-2.5-10.6-4.8c-0.6-0.5-2.1-3.4,2.8-21.2L48.6,64.1z"/>
        <Path d="M66.6,81.7c-1.6,1.1-3.7,1.1-5.3,0c-0.3-0.3-0.7-0.3-1.1-0.2c-0.4,0.1-0.7,0.5-0.7,0.9c-0.1,0.4,0.1,0.8,0.4,1
          c2.4,1.8,5.7,1.8,8,0c0.3-0.2,0.5-0.6,0.4-1c-0.1-0.4-0.3-0.7-0.7-0.9C67.4,81.3,66.9,81.4,66.6,81.7z"/>
        <Path d="M72.9,73.7c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2C71.9,71.4,72.9,72.4,72.9,73.7z"/>
        <Path d="M59.6,73.7c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2S59.6,72.4,59.6,73.7z"/>
        <Path d="M61.8,54.8h1.1v1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1v-1.1h1.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1
          h-1.1v-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v1.1h-1.1c-0.6,0-1.1,0.5-1.1,1.1C60.7,54.3,61.2,54.8,61.8,54.8z"/>
				<Circle cx="72.7" cy="30.9" r="0.5"/>
				<Circle cx="55.3" cy="30.9" r="0.5"/>
				<Circle cx="64.1" cy="39.5" r="0.5"/>
				<Path d="M63.5,33.9h-3c0.3-0.4,0.8-1,1.3-1.4c1-0.9,2-1.8,2-2.9c0-1.4-1.1-2.2-2.2-2.2c-1.1,0-2.4,0.6-2.4,2.4
					c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-1.3,1-1.4,1.4-1.4c0.5,0,1.2,0.4,1.2,1.2c0,0.7-0.9,1.5-1.7,2.2
					c-0.9,0.9-1.8,1.7-1.8,2.6v0.5h4.2c0.3,0,0.5-0.2,0.5-0.5S63.8,33.9,63.5,33.9z"/>
				<Path d="M68.9,31.9h-1v-4.2c0-0.2-0.1-0.4-0.4-0.5c-0.2-0.1-0.4,0-0.6,0.2l-2.9,4.7c-0.1,0.2-0.1,0.3,0,0.5
					c0.1,0.2,0.3,0.3,0.4,0.3h2.5v1.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-1.5h1c0.3,0,0.5-0.2,0.5-0.5
					S69.2,31.9,68.9,31.9z M66.9,31.9h-1.6l1.6-2.5V31.9z"/>
				<Path d="M75.8,29.9c-0.4,0-0.7,0.3-0.7,0.7c0,6.1-4.9,11-11,11s-11-4.9-11-11s4.9-11,11-11c2.5,0,4.8,0.8,6.8,2.3
					c0.3,0.2,0.8,0.2,1-0.1c0.2-0.3,0.2-0.8-0.1-1c-2.2-1.7-4.9-2.6-7.7-2.6c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5
					s12.5-5.6,12.5-12.5C76.5,30.3,76.2,29.9,75.8,29.9z"/>
				<Path d="M72.3,18.4c-0.4,0-0.7,0.3-0.7,0.7v2.7h-2.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h3.4c0.4,0,0.7-0.3,0.7-0.7
					v-3.5C73.1,18.7,72.7,18.4,72.3,18.4z"/>
				<Circle cx="64.1" cy="22.3" r="0.5"/>
      </Group>
    </SVG>
  )
}

export default Icon;