import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path, Rectangle } = styledTags;

const Icon = ({ primaryColor, ...otherProps }) => {

  return (
    <SVG xmlns='http://www.w3.org/2000/SVG' viewBox='0 0 128 128' fill={primaryColor} {...otherProps} >
      <Group>
        <Path d="M92,120H29.2l-5.6-10.7l5.5-3.7l-2.2-3.3l-5.1,3.4l-1.5-2.9l4.9-3.2l-2.2-3.3l-4.5,3L14,91l5.1-3.4
          c0.8-0.6,1.1-1.7,0.7-2.6L17,79.6c2.7,0.8,4.9,2.7,6.2,5.3l5,10c0.5,1,1.7,1.4,2.7,0.9c0.7-0.3,1.1-1,1.1-1.8V60h-4v25.5l-1.2-2.5
          c-2.5-5-7.7-8.1-13.3-7.9c-3.2,0.1-6.3,1.3-8.8,3.3c-0.7,0.6-0.9,1.6-0.5,2.5l22,42c0.3,0.7,1,1.1,1.8,1.1h66c1.1,0,2-0.9,2-2v-12
          h-4V120z M8.6,80.6c1.2-0.7,2.5-1.2,3.8-1.4l3,6.1l-3.2,2.2L8.6,80.6z"/>
        <Path d="M86,68c-2.7,0-5.2,1-7.1,2.9c-0.3,0.3-0.6,0.7-0.9,1.1c-3.3-4.4-9.6-5.3-14-2c-0.4,0.3-0.7,0.6-1.1,0.9
          c-0.3,0.3-0.6,0.7-0.9,1.1c-3.3-4.4-9.6-5.3-14-2V56c4.4,0,8-3.6,8-8v-6h-4v6c0,2.2-1.8,4-4,4H28c-2.2,0-4-1.8-4-4V12
          c0-2.2,1.8-4,4-4h20c2.2,0,4,1.8,4,4v14h4V12c0-4.4-3.6-8-8-8H28c-4.4,0-8,3.6-8,8v36c0,4.4,3.6,8,8,8h16v22c0,1.1,0.9,2,2,2
          s2-0.9,2-2c0-3.3,2.7-6,6-6s6,2.7,6,6c0,1.1,0.9,2,2,2s2-0.9,2-2c0-3.3,2.7-6,6-6s6,2.7,6,6c0,1.1,0.9,2,2,2s2-0.9,2-2
          c0-3.3,2.7-6,6-6s6,2.7,6,6v28h4V78C96,72.5,91.5,68,86,68z"/>
        <Path d="M30,12c-1.1,0-2,0.9-2,2v32c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V14c0-1.1-0.9-2-2-2H30z M44,44H32v-4h12V44z M44,36H32V16
          h12V36z"/>
        <Rectangle x="36" y="20" width="4" height="4"/>
        <Rectangle x="36" y="28" width="4" height="4"/>
        <Path d="M124,44V14c0-5.5-4.5-10-10-10H70c-5.5,0-10,4.5-10,10v12.9l-9,5.4c-0.9,0.6-1.3,1.8-0.7,2.7c0.2,0.3,0.4,0.5,0.7,0.7
          l9,5.4V54c0,5.5,4.5,10,10,10h44c5.5,0,10-4.5,10-10v-6h-4v6c0,3.3-2.7,6-6,6H70c-3.3,0-6-2.7-6-6V40c0-0.7-0.4-1.4-1-1.7L55.9,34
          l7.1-4.3c0.6-0.4,1-1,1-1.7V14c0-3.3,2.7-6,6-6h44c3.3,0,6,2.7,6,6v30H124z"/>
        <Path d="M68,36h4.2c0.3,0.4,0.7,0.8,1.1,1.1l17.3,16.3c0.8,0.7,2,0.7,2.7,0l17.4-16.3c0.4-0.4,0.8-0.8,1.1-1.2h4.2v-4h-2
          c2.3-6.6-1.3-13.8-7.9-16.1c-4.3-1.5-9-0.5-12.4,2.4l-1.8,1.6l-1.7-1.5C85,13.8,77,14.3,72.4,19.6c-3,3.4-3.9,8.1-2.5,12.4H68V36z
          M91.9,49.3L77.9,36H82c0.7,0,1.3-0.3,1.7-0.9l2.1-3.1l4.5,8.9c0.3,0.7,1,1.1,1.8,1.1c0,0,0.1,0,0.1,0c0.8,0,1.5-0.6,1.8-1.3
          l4.2-11.2l2.1,5.2c0.3,0.8,1,1.3,1.9,1.3h4.1L91.9,49.3z M73.5,25.7c0.8-2.9,3-5.2,5.8-6.1c0.8-0.3,1.7-0.4,2.5-0.4
          c2.1,0,4.1,0.8,5.7,2.2l3,2.6c0.8,0.7,1.9,0.7,2.6,0l3.1-2.7c3.6-3.1,9.1-2.8,12.2,0.8c2.4,2.7,2.8,6.6,1.1,9.8h-6.3l-3.5-8.7
          c-0.4-1-1.6-1.5-2.6-1.1c-0.5,0.2-0.9,0.6-1.1,1.2L91.7,35l-3.9-7.9c-0.5-1-1.7-1.4-2.7-0.9c-0.3,0.2-0.6,0.4-0.8,0.7L80.9,32h-6.7
          C73.3,30.1,73,27.8,73.5,25.7z"/>
        <Rectangle x="8" y="10.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.3848 11.7574)" width="4" height="11.3"/>
        <Rectangle x="4.3" y="42" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -28.1835 19.9583)" width="11.3" height="4"/>
        <Rectangle x="4" y="28" width="8" height="4"/>
      </Group>
    </SVG>
  )
}

export default Icon;