/** @jsx jsx */
import { jsx } from "@emotion/core";
import tw from "twin.macro";

/*
  Function to create HTML tags as custom React components that can have 
  styles attached to them, using Twin Macro.
*/
const createStyledTag = (as = "div") => {
  return (props) => {
    const StyledTag = tw(as)``;
    return <StyledTag {...props}>{props.children}</StyledTag>;
  };
};

/*
  A mapping of styled React components to create and their corresponding HTML tag.
*/
const componentTagMap = {
  // Basic
  Division: "div",
  Paragraph: "p",
  Span: "span",
  Image: "img",
  Anchor: "a",
  Button: "button",
  Heading: "h1",
  Break: "br",
  Quote: "blockquote",

  // Sections
  Main: "main",
  Section: "section",
  Article: "article",
  Aside: "aside",
  Container: "div",
  Row: "div",
  Column: "div",
  Navigation: "nav",
  Header: "header",
  Footer: "footer",

  // Lists
  UnorderedList: "ul",
  OrderedList: "ol",
  ListItem: "li",
  DescriptionList: "dl",
  Term: "dt",
  Description: "dd",

  // Time
  Time: "time",

  // Tables
  Table: "table",
  TableBody: "tbody",
  TableHeader: "thead",
  TableFooter: "tfoot",
  TableRow: "tr",
  TableHeaderCell: "th",
  TableCell: "td",

  // SVG
  SVG: "svg",
  Title: "title",
  Group: "g",
  Definitions: "defs",
  Pattern: "pattern",
  Path: "path",
  Circle: "circle",
  Ellipse: "ellipse",
  Polygon: "polygon",
  Rectangle: "rect",
  Text: "text",
};

const styledTags = {};

Object.entries(componentTagMap).forEach(([key, value]) => {
  styledTags[[key]] = createStyledTag(value);
});

export default styledTags;
