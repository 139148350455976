import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path } = styledTags;

const Icon = ({ primaryColor, ...otherProps }) => {

  return (
    <SVG xmlns='http://www.w3.org/2000/SVG' viewBox='0 0 128 128' fill={primaryColor} {...otherProps} >
      <Group>
        <Path d="M67.3,8h-1.4V6.6c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9V8h-1.4c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9h1.4v1.4
          c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9v-1.4h1.4c1,0,1.9-0.8,1.9-1.9C69.2,8.9,68.3,8,67.3,8z"/>
      </Group>
      <Group>
        <Path d="M118.7,121.6l-5.5-32c-0.6-3.5-3.1-6.3-6.4-7.3L82.3,75c-1.1-0.3-2.2-0.2-3.1,0.3c-0.9-0.8-1.5-2.1-1.5-3.3v-6.1
          c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3h2.4c3.3,0,6.4-1.3,8.7-3.8c2.3-2.4,3.4-5.6,3.2-8.9l-0.7-10.5V42
          c0-0.3,0-0.6-0.1-0.8L91,29.4c-0.1-1-1-1.8-2-1.7c-1,0.1-1.8,1-1.7,2l0.4,7c-0.5-0.1-1-0.2-1.5-0.2h-0.1c-0.5-0.8-1.5-1.4-2.5-1.5
          c-19.4-1.7-25-7.5-29.1-11.8c-0.8-0.9-2.2-0.9-4.1-0.2c-3.3,1.3-8.9,5.5-9,13c0,0,0,0,0,0v0.5c-0.4,0-0.7,0.1-1.1,0.2l0.6-9.1
          c0.3-4.8,2.7-9.2,6.6-11.9l0.1,0.4c0.9,2.5,3.3,4.1,6,4.1h21.3c2.6,0,5-1.6,6-4.1l0-0.1c2,1.5,3.6,3.5,4.7,5.8
          c0.3,0.7,1,1.1,1.7,1.1c0.3,0,0.5-0.1,0.8-0.2c0.9-0.4,1.3-1.6,0.9-2.5c-1.5-3.2-3.8-5.8-6.7-7.8l1.3-3.4C84.1,7.6,84,6.2,83.3,5
          c-0.7-1.2-1.8-2-3.1-2.3L69.4,0.5c-3.4-0.7-6.9-0.7-10.4,0L48.3,2.7C47,3,45.8,3.8,45.2,5c-0.7,1.2-0.8,2.6-0.3,3.9l1.2,3.1
          c-5.3,3.4-8.6,8.9-9,15.3l-0.9,14c0,0.3-0.1,0.5-0.1,0.8v0.2l-0.7,10.5c-0.2,3.3,0.9,6.5,3.2,8.9c2.3,2.4,5.4,3.8,8.7,3.8h2.4
          c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2V72c0,1.2-0.6,2.6-1.5,3.3c-0.9-0.5-2.1-0.7-3.1-0.3l-24.5,7.4
          c-3.4,1-5.8,3.8-6.4,7.3l-2.1,12c-0.2,1,0.5,2,1.5,2.2c1,0.2,2-0.5,2.2-1.5l2.1-12c0.4-2.1,1.8-3.7,3.8-4.3l24.5-7.4
          c0.2,0,0.3,0,0.4,0.2c0,0,0,0,0,0l11.7,23.1c0.3,0.6,0.7,1.1,1.2,1.6c1,1,2.4,1.6,3.9,1.6c1,0,1.9-0.2,2.7-0.7
          c1-0.5,1.9-1.4,2.5-2.5l11.7-23.1c0.1-0.1,0.2-0.2,0.4-0.2l24.5,7.4c2,0.6,3.5,2.3,3.8,4.3l5.5,32c0.1,0.7-0.2,1.2-0.4,1.4
          s-0.6,0.6-1.3,0.6h-11.6l-3.1-17.9c-0.2-1-1.1-1.7-2.2-1.5c-1,0.2-1.7,1.1-1.5,2.2l2.9,17.3H30L33,107c0.2-1-0.5-2-1.5-2.2
          c-1-0.2-2,0.5-2.2,1.5l-3.1,17.9H14.7c-0.7,0-1.1-0.4-1.3-0.6c-0.2-0.2-0.5-0.7-0.4-1.4l1.9-11.1c0.2-1-0.5-2-1.5-2.2
          c-1-0.2-2,0.5-2.2,1.5l-1.9,11.1c-0.3,1.6,0.2,3.2,1.2,4.5c1,1.2,2.6,1.9,4.2,1.9c0,0,0,0,0,0h98.7c1.6,0,3.1-0.7,4.2-1.9
          C118.6,124.8,119,123.2,118.7,121.6z M48.5,6.8c0.1-0.1,0.3-0.4,0.6-0.5l10.7-2.2c1.5-0.3,3-0.4,4.4-0.4c1.5,0,3,0.1,4.4,0.4
          l10.7,2.2c0,0,0,0,0,0c0.4,0.1,0.5,0.3,0.6,0.5c0.1,0.1,0.2,0.4,0.1,0.8l-2.7,7.1c-0.4,1-1.4,1.7-2.5,1.7H53.6
          c-1.1,0-2.1-0.7-2.5-1.7l-2.7-7.1C48.3,7.2,48.4,7,48.5,6.8z M88.8,52.9c0.1,2.3-0.6,4.5-2.2,6.1c-1.3,1.4-2.9,2.2-4.7,2.5
          c2.1-2.5,4.1-5.9,4.6-9.7c0.8,0,1.5-0.2,2.2-0.6L88.8,52.9z M41.4,59.1c-1.6-1.7-2.4-3.8-2.2-6.1l0.1-1.7c0.7,0.3,1.4,0.5,2.2,0.6
          c0.6,3.8,2.6,7.2,4.6,9.7C44.4,61.3,42.7,60.4,41.4,59.1z M66,99.7l-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1
          c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0-0.1,0.1c-0.3,0.3-0.8,0.4-1.2,0.4c-0.8,0-1.5-0.4-1.8-1.1l-7.8-15.3h19.1
          L66,99.7z M75.5,81.1H52.5l-1.4-2.9c1.8-1.5,2.9-3.9,2.9-6.3v-3.2c2.4,1.6,5.5,2.8,9.9,2.8c0,0,0,0,0,0c0,0,0,0,0,0
          c4.4,0,7.5-1.3,9.9-2.8V72c0,2.4,1.2,4.8,2.9,6.3L75.5,81.1z M75.4,62.8c-3.5,3-6.1,4.9-11.4,5c-5.3-0.1-8-2-11.4-5
          c-4.6-4-7.4-8.8-7.5-12.9c0-1-0.9-1.8-1.9-1.8h-1.4c-1,0-1.9-0.8-1.9-1.9V42c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
          c0.2-0.9,0.9-1.6,1.9-1.6h1.4c1,0,1.9-0.8,1.9-1.9V36c0.1-6.3,5.5-9.3,7.2-9.7c5.5,5.6,12.2,10.7,30.7,12.4
          c0.2,0.9,0.9,1.5,1.8,1.5h1.4c0.9,0,1.7,0.7,1.9,1.6c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3v4.2c0,1-0.8,1.9-1.9,1.9h-1.4
          c-1,0-1.8,0.8-1.9,1.8C82.8,54.1,80,58.9,75.4,62.8z"/>
	    </Group>
    </SVG>
  )
}

export default Icon;