import React from 'react';
// eslint-disable-next-line
import tw from 'twin.macro';

import styledTags from 'components/styledtags';

const { SVG, Group, Path } = styledTags;

const Logo = ({ primaryColor, secondaryColor, ...otherProps }) => {

  return (
    <SVG xmlns="http://www.w3.org/2000/SVG" viewBox="0 0 1119.52 330.95" {...otherProps} >
      <Group>
        <Group>
          <Path fill={secondaryColor || '#73cddd'} d="M357.82,85.34c-0.15,0-0.22,0-0.37,0c-0.73-0.15-1.32-0.59-1.61-1.25l-7.33-16.64l-4.55,7.4
            c-0.37,0.66-1.1,1.03-1.91,1.03h-15.32c-1.25,0-2.2-0.95-2.2-2.2c0-1.25,0.95-2.2,2.2-2.2h14.08l6.08-9.97
            c0.44-0.73,1.25-1.1,2.05-1.03c0.81,0.07,1.54,0.59,1.83,1.32l7.7,17.45l6.67-7.04c0.44-0.44,1.03-0.66,1.61-0.66h17.74
            c1.25,0,2.2,0.95,2.2,2.2c0,1.25-0.95,2.2-2.2,2.2h-16.79l-8.28,8.72C358.99,85.12,358.4,85.34,357.82,85.34z"/>
          <Path fill={primaryColor || 'white'} d="M357.08,104.4c-0.59,0-1.1-0.22-1.54-0.59l-19.5-18.84c-0.88-0.88-0.88-2.2-0.07-3.08
            c0.88-0.88,2.27-0.88,3.08-0.07l17.96,17.37l22.65-21.92c2.71-2.79,4.33-6.74,4.33-10.78s-1.54-7.92-4.4-10.85
            c-2.42-2.42-5.57-3.74-9.09-3.74c0,0,0,0-0.07,0c-4.25,0-8.58,1.83-11.8,5.13c-0.95,0.95-2.42,0.95-3.3,0
            c-4.03-3.81-7.55-5.79-10.48-5.79c-4.03,0-7.77,1.61-10.48,4.47c-2.05,2.05-3.52,4.84-4.11,7.92c-0.22,1.17-1.39,1.98-2.57,1.76
            c-1.17-0.44-1.98-1.61-1.69-2.79c0.81-3.96,2.64-7.48,5.28-10.12c3.52-3.74,8.43-5.79,13.64-5.79c3.74,0,7.7,1.91,12.17,5.79
            c3.89-3.23,8.65-5.06,13.49-5.06c0,0,0,0,0.07,0c4.69,0,9.02,1.83,12.24,5.06c3.67,3.81,5.64,8.72,5.64,13.93
            s-2.05,10.26-5.64,13.93l-24.27,23.46C358.18,104.18,357.67,104.4,357.08,104.4z"/>
        </Group>
        <Group fill={primaryColor || 'white'}>
          <Path d="M77.58,299.38V179.57c0-8.87,1.62-17.24,4.86-25.09c3.24-7.85,7.68-14.63,13.31-20.35
            c5.63-5.72,12.29-10.24,19.97-13.57c7.68-3.33,15.87-4.99,24.58-4.99s16.85,1.66,24.45,4.99c7.59,3.33,14.25,7.9,19.97,13.7
            c5.72,5.8,10.2,12.59,13.44,20.35c3.24,7.77,4.86,16.09,4.86,24.96c0,8.88-1.58,17.2-4.74,24.96c-3.16,7.77-7.55,14.55-13.18,20.35
            c-5.63,5.8-12.29,10.37-19.97,13.7c-7.68,3.33-15.96,4.99-24.83,4.99c-10.92,0-21.08-2.56-30.46-7.68v-23.04
            c8.36,6.14,18.26,9.22,29.7,9.22c6.14,0,11.73-1.06,16.77-3.2c5.03-2.13,9.3-5.08,12.8-8.83c3.5-3.75,6.18-8.23,8.06-13.44
            c1.88-5.2,2.82-10.88,2.82-17.02s-0.94-11.82-2.82-17.02c-1.88-5.2-4.52-9.68-7.94-13.44c-3.42-3.75-7.55-6.7-12.42-8.83
            c-4.86-2.13-10.37-3.2-16.51-3.2c-5.98,0-11.39,1.02-16.26,3.07c-4.86,2.05-9.05,4.95-12.54,8.7c-3.5,3.76-6.19,8.24-8.06,13.44
            c-1.88,5.21-2.82,10.97-2.82,17.28v119.81H77.58z"/>
          <Path d="M226.05,241.01v-69.89c0-19.62,3.67-33.19,11.01-40.7c3.58-3.75,7.94-6.57,13.06-8.45
            c5.12-1.88,9.6-2.98,13.44-3.33c3.84-0.34,9.09-0.51,15.74-0.51h22.53v21.5h-27.14c-3.42,0-6.06,0.09-7.94,0.26
            c-1.88,0.17-4.1,0.77-6.66,1.79s-4.78,2.65-6.66,4.86c-3.24,4.1-4.86,12.29-4.86,24.58v69.89H226.05z"/>
	        <Path d="M369.75,241.01h-22.53V139.63h-20.99l10.24-21.5h33.28V241.01z"/>
          <Path d="M405.33,118.13h112.9c12.12,0,21.76,2.3,28.93,6.91c7.34,4.61,12.16,10.58,14.46,17.92
            c2.3,7.34,3.46,16.13,3.46,26.37v71.68h-22.53v-71.68c0-13.31-4.01-22.1-12.03-26.37c-4.1-2.22-12.38-3.33-24.83-3.33h-10.5v101.38
            h-22.53V139.63h-44.8v101.38h-22.53V118.13z"/>
          <Path d="M609.87,134.26c11.94-12.46,26.88-18.69,44.8-18.69c8.7,0,16.85,1.66,24.45,4.99
            c7.59,3.33,14.25,7.9,19.97,13.7c5.72,5.8,10.2,12.59,13.44,20.35c3.24,7.77,4.87,16.09,4.87,24.96c0,8.88-1.58,17.2-4.74,24.96
            c-3.16,7.77-7.55,14.55-13.18,20.35c-5.63,5.8-12.29,10.37-19.97,13.7c-7.68,3.33-15.96,4.99-24.83,4.99
            c-9.05,0-17.37-1.66-24.96-4.99c-7.6-3.33-14.21-7.89-19.84-13.7c-5.63-5.8-10.03-12.58-13.18-20.35
            c-3.16-7.76-4.74-16.08-4.74-24.96C591.95,161.48,597.92,146.38,609.87,134.26z M654.67,137.07c-11.95,0-21.55,3.93-28.8,11.78
            c-7.26,7.85-10.88,18.09-10.88,30.72c0,12.63,3.62,22.87,10.88,30.72c7.25,7.85,16.85,11.78,28.8,11.78
            c12.12,0,21.76-3.97,28.93-11.9c7.17-7.94,10.75-18.13,10.75-30.59c0-12.46-3.58-22.66-10.75-30.59
            C676.43,141.04,666.79,137.07,654.67,137.07z"/>
        </Group>
        <Group fill={secondaryColor || '#73cddd'}>
          <Path d="M747.99,118.1h112.9c12.12,0,21.76,2.3,28.93,6.91c7.34,4.61,12.16,10.58,14.46,17.92
            c2.3,7.34,3.46,16.13,3.46,26.37v71.68H885.2V169.3c0-13.31-4.01-22.1-12.03-26.37c-4.1-2.22-12.38-3.33-24.83-3.33h-10.5v101.38
            h-22.53V139.61h-44.8v101.38h-22.53V118.1z"/>
          <Path d="M1058.81,60.48v119.81c0,8.87-1.62,17.24-4.86,25.09c-3.24,7.85-7.68,14.63-13.31,20.35
            c-5.63,5.72-12.29,10.24-19.97,13.57c-7.68,3.33-15.87,4.99-24.58,4.99s-16.85-1.66-24.45-4.99c-7.59-3.33-14.25-7.9-19.97-13.7
            c-5.72-5.8-10.2-12.59-13.44-20.35c-3.24-7.77-4.86-16.09-4.86-24.96c0-8.88,1.58-17.2,4.74-24.96
            c3.16-7.77,7.55-14.55,13.18-20.35c5.63-5.8,12.29-10.37,19.97-13.7c7.68-3.33,15.96-4.99,24.83-4.99
            c10.92,0,21.08,2.56,30.46,7.68V147c-8.36-6.14-18.26-9.22-29.7-9.22c-6.14,0-11.73,1.06-16.77,3.2c-5.03,2.13-9.3,5.08-12.8,8.83
            c-3.5,3.75-6.18,8.23-8.06,13.44c-1.88,5.2-2.82,10.88-2.82,17.02s0.94,11.82,2.82,17.02c1.88,5.2,4.52,9.68,7.94,13.44
            c3.42,3.75,7.55,6.7,12.42,8.83c4.86,2.13,10.37,3.2,16.51,3.2c5.98,0,11.39-1.02,16.26-3.07c4.86-2.05,9.05-4.95,12.54-8.7
            c3.5-3.76,6.19-8.24,8.06-13.44c1.88-5.21,2.82-10.97,2.82-17.28V60.48H1058.81z"/>
        </Group>
      </Group>
    </SVG>
  )
}

export default Logo;
